import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../../pages/ButtomNav/Navbar";
import backArrow from "../../../../assets/arrow-white.png";
import passwordShow from "../../../../assets/password-show.svg";
import showPasswords from "../../../../assets/password-show-icon.svg";
import buttomLine from "../../../../assets/line.svg";
import Reset from "../../../../assets/reset.png";
import { useCreateNewPasswordMutation } from "../../../../Services/ForgotPasswordApi";
import { toast } from "react-toastify";
import { FormValidation, FormValidationOnChange } from "./changePassValidation";
// import SettingNav from '../../SettingNav'
import Settings from "../../Settings";
import Sidebar from "../../../../components/Sidebar";
import dlogo from "../../../../assets/dlogo.png"
import AccountInformation from "../../accountInformation/AccountInformation";
import Legal from "../../legal/Legal";
import Support from "../../support/Support";
import ViewUserProfile from "../../accountInformation/userProfile/ViewUserProfile";
import notifications from "../../../../assets/notification-circle.svg";


const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [createNewPass] = useCreateNewPasswordMutation();
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errField, setErrField] = useState({
    passwordErr: "",
    confirmPasswordErr: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const changePasswordHandler = (e) => {
    e.preventDefault();

    if (FormValidation({ inputField, setErrField })) {
      if (inputField?.password.trim() !== inputField.confirmPassword.trim()) {
        return toast.error("Password and confirmed password do not match");
      }
      let user = localStorage.getItem("email");
      const inputData = {
        email: user,
        password: inputField?.password,
      };
      createNewPass(inputData)
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          if (res?.success) {
            navigate("/settings");
          }
        })
        .catch((err) => {
          toast.error(err?.data?.message);
        });
    }
    //navigate('/login')
  };

  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    let inputName = e.target.name;
    let inputValue = e?.target?.value;
    FormValidationOnChange({ inputValue, setErrField, inputName });
  };

  return (
    <>
      <div className="container-fluid bg-five">
        <div className="row px-0 pb-5 m-0">
          <div className="col-md-2 col-lg-1 new ">
            <Sidebar />
          </div>

          <div className="col-md-10 col-lg-11">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 p-0 m-0">
                    <div className="section">
                      <div className="">
                        <div className="top">
                          <div className="container-fluid  mt-40 p-0 mx-0">
                            <div className="row align-items-center p-0 m-0">
                              <div className="col-3 p-0 m-0">
                                
                              </div>

                              <div className="col-6 p-0 m-0">
                                <img
                                  src={dlogo}
                                  alt="notification"
                                  className="img-fluid mx-auto d-block"
                                  style={{ width: 150 }}
                                />
                              </div>

                              <div className="col-3 p-0 mx-0">
                                <Link
                                  to={"/notifications"}
                                  className="notificaition-common border-0 p-0 list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <div className="ms-auto">
                                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                                      <img
                                        src={notifications}
                                        alt="notifications"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                  {/* <span className="">
                          <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                      </span> */}
                                </Link>
                              </div>
                              <div className="col-6 mt-5">
                                <h5 className="text-black fw-bold mb-4  borderLEFT">
                                  Settings
                                </h5>
                              </div>
                              {/* <div className="col-12">
                            <h3 className="design-color fw-bold mt-5">
                              {usrname}
                            </h3>
                          </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="middle">
                          <div className="container-fluid">
                            <div className="row">
                              
                              <div className="col-md-12">
                                {/* <p className="mb-4 text-uppercase text-decoration-underline ">
                              Account Info
                            </p> */}

                                <div className="list-group  text-nowrap list-group-horizontal gap-50 mt-2 user-profile-options">
                                  <AccountInformation />
                                  <Support />
                                  <Legal />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Navbar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="section bg-colorss bg-white mb-5 mb-md-3">
                  <div className="">
                    <div className="top">
                      <div className="container-fluid px-md-5 mt-4">
                        <div className="row">
                          <div className="col-md-12">
                            {/* <Link  className="back-buttons"  to={'/settings'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link> */}
                            {/* <h4 className="design-color fw-bold mt-4">Change Password</h4>
                      
                                <p className="mt-3 text-muted fs-5 mb-0">Your new password must be different from previously used passwords.</p> */}

                            <h5 className="design-color fw-bolder mt-4 mb-1 text-center">
                              Change Password
                            </h5>
                            <p className="text-muted text-center mb-5 mt-2">
                              Your new password must be different from
                              previously used passwords.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="middle bg-white">
                      <div className="container-fluid px-md-5 mb-4">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="card border-0 rounded bg-colorss bg-white" style={{ border: "none !important" }}>
                              <div className="card-body p-0">
                                <img
                                  src={Reset}
                                  alt="forgot"
                                  className="img-fluid forgot-size  mx-auto d-block"
                                />

                                <form action="" className="form-login">
                                  <div className="row justify-content-center mt-3">
                                    <div className="col-md-12">
                                      <div className="position-relative mb-4">
                                        <label className="form-label">
                                          New Password
                                        </label>
                                        <input
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          className="form-control py-3 position-relative"
                                          placeholder="Password"
                                          aria-label="password"
                                          aria-describedby="password"
                                          name="password"
                                          value={inputField?.password}
                                          onChange={inputChangeHandler}
                                        />
                                        <img
                                          src={
                                            showPassword
                                              ? showPasswords
                                              : passwordShow
                                          }
                                          alt="password-show"
                                          className="img-fluid position-absolute password-show"
                                          onClick={togglePasswordVisibility}
                                        />
                                        {errField?.passwordErr?.length > 0 && (
                                          <span className="error">
                                            {errField?.passwordErr}
                                          </span>
                                        )}
                                      </div>

                                      <div className="position-relative">
                                        <label className="form-label">
                                          Confirm Password
                                        </label>
                                        <input
                                          type={
                                            showConfirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          className="form-control py-3 position-relative"
                                          placeholder="Confirm Password"
                                          aria-label="confirmpassword"
                                          aria-describedby="confirmpassword"
                                          name="confirmPassword"
                                          value={inputField?.confirmPassword}
                                          onChange={inputChangeHandler}
                                        />
                                        <img
                                          src={
                                            showConfirmPassword
                                              ? showPasswords
                                              : passwordShow
                                          }
                                          alt="password-show"
                                          className="img-fluid position-absolute password-show"
                                          onClick={
                                            toggleConfirmPasswordVisibility
                                          }
                                        />
                                      </div>
                                      {errField?.confirmPasswordErr?.length >
                                        0 && (
                                          <span className="error">
                                            {errField?.confirmPasswordErr}
                                          </span>
                                        )}
                                    </div>
                                  </div>

                                  <div className="col-md-10 mx-auto">
                                    <div className="text-center">
                                      <button
                                        type="submit"
                                        onClick={changePasswordHandler}
                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3"
                                      >
                                        Update Password
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bottom">
                      <div className="container-fluid px-md-100">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-center">
                              <img
                                src={buttomLine}
                                alt="line"
                                className="line img-fluid mx-auto"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Settings /> */}
    </>
  );
};

export default ChangePassword;
