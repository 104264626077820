import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    CreateProduct: builder.mutation({
        query: (data) => ({
          url: "/retailer/create-product",
          method: "POST",
          body: data,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          
        }),
      }),
      getTotalProduct: builder.query({
        query: ({ page, limit, productName,sortBys }) => ({
          url: `/retailer/get-all-product?page=${page}&limit=${limit}&productName=${productName}&sortBy=${sortBys}`,
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
      getSingleCategoryInsideProduct: builder.query({
        query: ({activeCategory,activeSubCategory, page, limit, productName,sortBys }) => ({
          url: `/retailer/products/category/${activeCategory}/${activeSubCategory?activeSubCategory:""}?page=${page}&limit=${limit}&productName=${productName}&sortBy=${sortBys}`,
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
      DeleteProduct: builder.mutation({
        query: (id) => ({
          url: `/retailer/product-delete/${id}`,
          method: "DELETE",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
      GetSingleProduct: builder.query({
        query: (id) => ({
          url: `/retailer/get-single-product/${id}`,
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
      EditProduct: builder.mutation({
        query: ({ id, data }) => ({
          url: `/retailer/edit-product/${id}`,
          method: "PUT",
          body: data,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
      productActiveUnactive: builder.mutation({
        query: (data) => ({
          url: `/retailer/product-active-unactive`,
          method: "PUT",
          body: data,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
      UploadCSVFile: builder.mutation({
        query: (data) => ({
          url: "/retailer/import-products-csv",
          method: "POST",
          body: data,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          
        }),
      }),
  }),
});

export const {
  useCreateProductMutation,
  useGetTotalProductQuery,
  useGetSingleCategoryInsideProductQuery,
  useDeleteProductMutation,
  useGetSingleProductQuery,
  useEditProductMutation,
  useProductActiveUnactiveMutation,
  useUploadCSVFileMutation
} = productApi;