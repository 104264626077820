import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useGetAllCategoryQuery } from "../../../Services/CategoryApi";

const FilterBy = (props) => {
  const {
    showFilter,
    handleClose,
    onCategoryIdPass,
    selectedCategoryIds: parentSelectedCategoryIds,
  } = props;
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(
    parentSelectedCategoryIds
  );
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [visibleSubcategories, setVisibleSubcategories] = useState({});
  const { data } = useGetAllCategoryQuery({
    categoryIds: "",
    subcategoriesIds: "",
  });

  useEffect(() => {
    if (data) {
      setCategoryList(data?.data);
    }
  }, [data]);

  useEffect(() => {
    if (showFilter) {
      setSelectedCategoryIds(parentSelectedCategoryIds);
      const initialVisibleSubcategories = {};
      parentSelectedCategoryIds?.forEach((categoryId) => {
        initialVisibleSubcategories[categoryId] = true;
      });
      setVisibleSubcategories(initialVisibleSubcategories);
    }
  }, [showFilter, parentSelectedCategoryIds]);

  const handleCheckboxChange = (categoryId) => {
    setSelectedCategoryIds((prevSelectedCategoryIds) => {
      if (prevSelectedCategoryIds?.includes(categoryId)) {
        setVisibleSubcategories((prevVisibleSubcategories) => {
          const updated = { ...prevVisibleSubcategories };
          delete updated[categoryId];
          return updated;
        });
        setSelectedSubcategoryIds((prevSelectedSubcategoryIds) =>
          prevSelectedSubcategoryIds.filter(
            (id) => !id.startsWith(`${categoryId}-`)
          )
        );
        return prevSelectedCategoryIds.filter((id) => id !== categoryId);
      } else {
        setVisibleSubcategories((prevVisibleSubcategories) => ({
          ...prevVisibleSubcategories,
          [categoryId]: true,
        }));
        const selectedCategory = categoryList.find(
          (category) => category._id === categoryId
        );
        const subcategoryIds = selectedCategory?.subCategory?.map(
          (subcategory) => subcategory._id
        );
        setSelectedSubcategoryIds((prevSelectedSubcategoryIds) => [
          ...prevSelectedSubcategoryIds,
          ...subcategoryIds,
        ]);
        return [...prevSelectedCategoryIds, categoryId];
      }
    });
  };

  const handleSubcategoryChange = (subcategoryId, categoryId) => {
    if (selectedCategoryIds?.includes(categoryId)) {
      return;
    }

    setSelectedSubcategoryIds((prevSelectedSubcategoryIds) => {
      if (prevSelectedSubcategoryIds?.includes(subcategoryId)) {
        return prevSelectedSubcategoryIds.filter((id) => id !== subcategoryId);
      } else {
        return [...prevSelectedSubcategoryIds, subcategoryId];
      }
    });
  };

  const applyFilters = () => {
    onCategoryIdPass(selectedCategoryIds, selectedSubcategoryIds);
    handleClose();
  };

  // Clear All functionality
  const clearAll = () => {
    setSelectedCategoryIds([]);
    setSelectedSubcategoryIds([]);
    setVisibleSubcategories({});
  };

  return (
    <Modal show={showFilter} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5 sort_by_color">Filter</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body
        className="px-md-4"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <div className="row">
          <div className="col-mb-4 col-lg-12 m-0">
            {categoryList?.map((category) => (
              <div key={category._id}>
                <div className="mb-3">
                  <div className="form-check d-flex align-items-center filter_div">
                    <input
                      type="checkbox"
                      id={`category-${category._id}`}
                      name="categories"
                      value={category._id}
                      className="custom-radio form-check-input"
                      checked={selectedCategoryIds?.includes(category._id)}
                      onChange={() => handleCheckboxChange(category._id)}
                    />
                    <label
                      htmlFor={`category-${category._id}`}
                      className="mx-2 form-check-label"
                    >
                      {category.categoryName}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 mb-4 justify-content-center w-100 pt-0">
        <div className="row justify-content-between w-100">
        <div className="col-4 p-0">
              <button 
                type="button"
                className="btn bg-button filter-applied w-100 rounded-pill text-white py-3 fs-16"
                onClick={applyFilters}
              >
                Apply
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                className="btn bg-button reject w-100 rounded-pill py-3 fs-16  filter-sort-modal"
                onClick={clearAll}
              >
                Clear
              </button>
            </div>
            <div className="col-4 p-0">
              <button
                type="button"
                className="btn bg-button reject w-100 rounded-pill py-3 fs-16 filter-sort-modal"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterBy;
