
export const FormValidation = ({ inputField, setErrField,subCategoryList }) => {
    const validateForm = () => {
        let errors = {};
        
        if(!inputField?.productName?.trim()){
            errors.productNameErr = "Please enter product name";
        }
        if(!inputField?.description?.trim()){
            errors.descriptionErr = "Please enter description";
        }
        if(!inputField?.category?.trim()){
            errors.categoryErr = "Please enter category name";
        }
        if(!inputField?.unit?.trim()){
            errors.unitErr = "Please select unit";
        }
        if(!inputField?.itemWeight?.trim()){
            errors.itemWeightErr = "Please enter item weight";
        }else if (inputField?.itemWeight <= 0) {
            errors.itemWeightErr = "Item weight must be greater than zero.";
          } 
        if(!inputField?.itemPrice){
            errors.itemPriceErr = "Please enter item price";
        }else if (inputField?.itemPrice <=0) {
            errors.itemPriceErr = "Item weight must be greater than zero.";
          } 


        if(!inputField?.quantity?.trim()){
            errors.quantityErr = "Please enter quantity";
        }
       

        setErrField(errors);
        return Object.keys(errors)?.length === 0;
    };

    return validateForm();
};



