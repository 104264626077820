import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import backArrow from "../../../assets/arrow-white.png";
import productItem from "../../../assets/product-item.png";
import { useGetOrderDetailQuery } from "../../../Services/OrderApi";
import { GetUrl } from "../../../config/GetUrl";

const OrderDetails = () => {
  const param = useParams();
  const { data } = useGetOrderDetailQuery({ id: param?.id });
  const [orderDetail, setOrderDetail] = useState();
  const [productDetails, setProductDetail] = useState([]);
  useEffect(() => {
    setOrderDetail(data?.data);
    setProductDetail(data?.data?.order_id?.products);
  }, [data, param]);

  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row align-items-center">
              <div className="col-md-12">
                <Link className="back-buttons" to={"/my-menu"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link>
              </div>

              <div className="col-md-12">
                <h4 className="design-color fw-bold mt-4 mb-4 text-center">
                  Order Details
                </h4>
              </div>

              <div className="col-md-12">
                <div className="float-end">
                  <button className="btn btn-delivery">Delivery</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="middle">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                {/* order summary starts */}
                <div className="card tab-card p-4 mb-5">
                  <h5 className="  text-black fw-bold mb-4 ">Order Summary</h5>
                  <ul className=" grid-d list-group border-0">
                    <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-start align-items-start flex-column">
                      <div className="me-auto">
                        <div className="">Order ID</div>
                      </div>
                      <span className="fw-bold text-black">
                        #{orderDetail?.order_id?.doordash_order_id}
                      </span>
                    </li>

                    <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                      <div className="me-auto">
                        <div className="">Order date</div>
                      </div>
                      <span className="fw-bold text-black">
                        {orderDetail?.createdAt}
                      </span>
                    </li>

                    <li className="p-0 mb-0 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                      <div className="me-auto">
                        <div className="">Order Type</div>
                      </div>
                      <span className="fw-bold text-black text-end">
                        {orderDetail?.order_id?.orderPlace?.order_type}
                      </span>
                    </li>

                    <li className="p-0  mb-0 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                      <div className="me-auto">
                        <div className="">Order Status</div>
                      </div>
                      <span className="design-color fw-bold text-black text-end">
                        {orderDetail?.status}
                      </span>
                    </li>
                  </ul>
                </div>
                {/* order summary ends */}

                <div className="card tab-card item-gap">
                  <div className="card-body p-4 ">
                    <h5 className="  text-black fw-bold mb-4">Items</h5>

                    {productDetails?.map((item, index) => (
                      <div className="card tab-card" key={index}>
                        <div className="card-body product-card-body">
                          <div className="row align-items-center justify-content-center">
                            <div className="col-3">
                              <img
                                src={GetUrl.IMAGE_URL + item?.product?.images}
                                alt="product-item"
                                className="img-fluid image_size_fixed"
                              />
                            </div>
                            <div className="col-3">
                              <h6 className="text-black fw-bold order-name">
                                {item?.product?.productName}
                              </h6>
                              <p className="small mb-0 order-paragraph">
                                ${item?.product?.itemWeight}
                              </p>
                            </div>

                            <div className="col-3">
                              <div className="box order-quantity">
                                {item?.quantity}
                              </div>
                            </div>

                            <div className="col-3 text-center">
                              <h5 className="design-color fw-bold">
                                ${item?.product?.itemPrice}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {console.log("orderDetail----->", orderDetail)}
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card tab-card p-4 mb-5 item-gap">
                      <h5 className=" text-black fw-bold mb-4">User Details</h5>

                      <ul className="list-group list-group-flush border-0 grid-d">
                        <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-start align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Name</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            {orderDetail?.order_id?.orderPlace?.user?.fullName}
                          </span>
                        </li>

                        <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Mobile Number</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            {
                              orderDetail?.order_id?.orderPlace?.user
                                ?.mobileNumber
                            }
                          </span>
                        </li>

                        <li className="p-0 mb-0 border-0 list-group-item d-flex justify-content-start align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Products</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            {productDetails?.length}
                          </span>
                        </li>

                        <li className="p-0 mb-0 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Delivery Address</div>
                          </div>
                          <span className="fw-bold text-black">
                            {orderDetail?.order_id?.orderPlace?.addressId
                              .addressType +
                              "," +
                              orderDetail?.order_id?.orderPlace?.addressId
                                .floor +
                              "," +
                              orderDetail?.order_id?.orderPlace?.addressId
                                .streetAddress +
                              "," +
                              orderDetail?.order_id?.orderPlace?.addressId
                                .zipcode}
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="card tab-card p-4 mt-5">
                      <h5 className="text-black fw-bold mb-4">
                        Payment Summary
                      </h5>
                      <ul className="list-group list-group-flush border-0 grid-d ">
                        <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Item Subtotal</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            ${orderDetail?.order_id?.checkout?.itemSubtotal}
                          </span>
                        </li>

                        <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Delivery Fee</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            {orderDetail?.order_id?.checkout?.deliveryFee == 0
                              ? "Free"
                              : orderDetail?.order_id?.checkout?.deliveryFee}
                          </span>
                        </li>

                        <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-between align-items-start flex-column">
                          <div className="me-auto">
                            <div className="">Service Fee</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            ${orderDetail?.order_id?.checkout?.serviceFee}
                          </span>
                        </li>
                        <li className="p-0 mb-4 border-0 list-group-item d-flex justify-content-start align-items-start mb-3 flex-column">
                          <div className="me-auto">
                            <div className="">Tax</div>
                          </div>
                          <span className="fw-bold text-black text-end">
                            $
                            {orderDetail?.order_id?.checkout?.tax
                              ? orderDetail?.order_id?.checkout?.tax
                              : 5}
                          </span>
                        </li>

                        <li className="p-0   border-0 list-group-item d-flex justify-content-between align-items-start flex-column mb-0">
                          <div className="me-auto">
                            <div className="fw-bold">Subtotal</div>
                          </div>
                          <span className="fw-bold design-color">
                            ${orderDetail?.order_id?.checkout?.subTotal}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8 mx-auto">
                <div className="design-bgcolor p-3 my-4 text-center text-white rounded">
                  <p className="mb-0 text-uppercase fw-bold">
                    Delivery Date, Time - {orderDetail?.order_dropoff_time}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bottom py-md-4 py-2 b-color">
            <div className="container-fluid px-md-100">
                <div className="row">
                    <div className="col-6">
                        <div className="">
                             <button type="button" className="btn bg-button reject w-100 rounded-pill py-3">Reject</button>
                        </div>
                    </div>

                     <div className="col-6">
                        <div className="">
                             <button type="button" className="btn bg-button w-100 rounded-pill text-white py-3">Apply</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>   */}
      </div>
    </div>
  );
};

export default OrderDetails;
