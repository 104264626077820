import { useState } from 'react';
import { GetUrl } from '../../../config/GetUrl'
import Carousel from 'react-bootstrap/Carousel';
// import Background from "../../assets/sbg-image.jpg";
// import Background2 from "../../assets/slider-2.jpg";
// import Background3 from "../../assets/slider-3.jpg";

const ImageSlider = (props) => {
    const {images} = props
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Array of images with optional captions
//   const images = [
//     { src: Background, caption: 'First Slide', description: 'This is the first slide description.' },
//     { src: Background2, caption: 'Second Slide', description: 'This is the second slide description.' },
//     { src: Background3, caption: 'Third Slide', description: 'This is the third slide description.' },
//   ];

  return (
    <div className="h-100 height-sliders">
      <Carousel activeIndex={index} onSelect={handleSelect} >
        {images.map((image, idx) => (
          <Carousel.Item key={idx}>
            <img
              src={GetUrl?.IMAGE_URL + image}
              className="img-fluid image_size_fixed new-card-image"
              alt={`Slide ${idx + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};




export default ImageSlider