import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../ButtomNav/Navbar";
import notificationIcon from "../../assets/notification-icon.svg";
import plusIcon from "../../assets/plus.svg";
import searchIcon from "../../assets/search.svg";
import sortBy from "../../assets/sorting.png";
import filterBy from "../../assets/filters.png";
import AllProduct from "./allProduct/AllProduct";
import Sidebar from "../../components/Sidebar";
import notifications from "../../assets/notification-circle.svg";
import dlogo from "../../assets/dlogo.png";
import SortingCategory from "./sortingBy/SortingCategory";
import FilterBy from "./filterBy/FilterBy";
import { CSVLink } from "react-csv";

import { useGetAllCategoryQuery } from "../../Services/CategoryApi";
import {
  useGetTotalProductQuery,
  useGetSingleCategoryInsideProductQuery,
  useUploadCSVFileMutation,
} from "../../Services/ProductApi";
import { GetUrl } from "../../config/GetUrl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingAction from "../../components/loader/LoadingAction";
import LoadingImage from "../../components/loader/LoaderImage";

const Products = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [sortBys, setSortBy] = useState("");
  const [categoriesIds, setCategoriesId] = useState();
  const [subcategoriesIds, setSubcategoriesId] = useState("");
  const [productData, setProductData] = useState([]);
  const [totalProduct, setTotalProduct] = useState("");
  const [categoryList, setCategoryList] = useState([""]);
  const [subCategoryList, setSubCategoryList] = useState([""]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const updatedProduct = useSelector((state) => state.productSlice);
  const [showLoader, setShowLoader] = useState(false);
  const [UploadCsvFile] = useUploadCSVFileMutation();
  const [getProductAPIRefresh, setGetProductAPIRefresh] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [imageLoader, setImageLoaders] = useState(true);
  const [getDeleteProductAPIRefresh, setGetDeleteProductAPIRefresh] =
    useState(false);
  const categoryIds = Array.isArray(categoriesIds)
    ? categoriesIds.join(",")
    : "";
  const { data: categoryData } = useGetAllCategoryQuery({
    categoryIds,
    subcategoriesIds,
  });

  const {
    data: productList,
    isLoading,
    isFetching,
    refetch: refetcProductList,
  } = useGetTotalProductQuery({ page: 1, limit: 95, productName, sortBys });
  const { data: productListInsideCategory, refetch: refetchCategoryProducts } =
    useGetSingleCategoryInsideProductQuery(
      {
        activeCategory,
        activeSubCategory,
        page: 1,
        limit: 95,
        productName,
        sortBys,
      },
      { skip: !activeCategory }
    );
  useEffect(() => {
    if (
      updatedProduct.updated_product === "Product has been updated" ||
      getProductAPIRefresh ||
      getDeleteProductAPIRefresh
    ) {
      refetcProductList();
    }
  }, [updatedProduct, getProductAPIRefresh, getDeleteProductAPIRefresh]);

  useEffect(() => {
    if (activeCategory) {
      refetchCategoryProducts();
    }
  }, [activeCategory]);

  useEffect(() => {
    if (categoryData) {
      setCategoryList(categoryData.data);
      setImageLoaders(false); // Initialize loaders for each image
      if (categoryData.success) {
        setShowLoader(false);
      }
    }
  }, [categoryData]);

  useEffect(() => {
    if (!activeCategory) {
      if (productList) {
        setProductData(productList.data);
        setTotalProduct(productList.totalProduct);
        setShowLoader(false);
      }
    } else {
      if (productListInsideCategory) {
        setProductData(productListInsideCategory.data);
        setTotalProduct(productListInsideCategory.totalProduct);
        setShowLoader(false);
      }
    }
  }, [productList, productListInsideCategory, activeCategory, updatedProduct]);

  const addProductHandler = (e) => {
    e.preventDefault();
    navigate("/create-product");
  };

  const productHandler = (e, id, subCategory) => {
    e.preventDefault();
    setActiveCategory(id);
    setSubCategoryList(subCategory);
    setActiveSubCategory(null);
  };

  const subCategoryHandler = (e, id) => {
    e.preventDefault();
    setActiveSubCategory(id);
  };

  const sortingHandler = () => {
    setShow(true);
  };

  const filterHandler = () => {
    setShowFilter(true);
  };

  const handleClose = () => {
    setShow(false);
    setShowFilter(false);
  };

  const handleDataChange = (newData) => {
    setSortBy(newData);
    setSelectedFilter(newData);
    setShowLoader(true);
  };

  const onCategoryPropsChangeHandler = (newCategory) => {
    setCategoriesId(newCategory);
    setShowLoader(true);
    setSelectedCategoryIds(newCategory);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
    if (file) {
      const formData = new FormData();
      formData.append("csv_file", file);
      setShowLoader(true);
      UploadCsvFile(formData)
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          setShowLoader(false);
          if (res.success) {
            setGetProductAPIRefresh(true);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.error(err?.data?.message);
        });
    }
  };

  const productDeleteHandler = (newData) => {
    if (newData.success) {
      setGetDeleteProductAPIRefresh(true);
    }
  };

  //productName, description, itemWeight, itemPrice, category, subCategory, quantity, unit


  const staticData = [
    { categoryName:"Vegetables & Fruits", 
      productName: "Grapes", 
      description: "A grape is a fruit, botanically a berry, of the deciduous woody vines of the flowering plant genus Vitis. Grapes are a non-climacteric type of fruit, generally occurring in clusters.", 
      itemWeight: "1",
      itemPrice: 180,
      quantity: 1,
      unit: "kg",
    },
    { categoryName:"Vegetables & Fruits", 
      productName: "Mango", 
      description: "A mango is an edible stone fruit produced by the tropical tree Mangifera indica. It originated from the region between northwestern Myanmar, Bangladesh, and northeastern India.", 
      itemWeight: "2",
      itemPrice: 500,
      quantity: 1,
      unit: "kg",
    },
  ];

  const headers = [
    { label: "CategoryName", key: "categoryName" },
    { label: "ProductName", key: "productName" },
    { label: "Description", key: "description" },
    { label: "ItemWeight", key: "itemWeight" },
    { label: "ItemPrice", key: "itemPrice" },
    { label: "Quantity", key: "quantity" },
    { label: "Unit", key: "unit" },
  ];






  return (
    <>
      <div className="row px-0 pb-5 m-0">
        <div className="col-md-2 col-lg-1 new ">
          <Sidebar />
        </div>

        <div className="col-md-10 col-lg-11 px-2">
          <div className="section">
            <div className="height-100">
              <div className="top">
                <div className="container-fluid mt-40 p-0 mx-0">
                  <div className="row  align-items-center p-0 m-0">
                    <div className="col-3 p-0 m-0">
                      {/* <h5 className="text-black fw-bold mb-0 design-color">Home</h5> */}
                    </div>

                    <div className="col-6 p-0 m-0">
                      <img
                        src={dlogo}
                        alt="notification"
                        className="img-fluid mx-auto d-block"
                        style={{ width: 150 }}
                      />
                    </div>

                    <div className="col-3 p-0 m-0">
                      <Link
                        to={"/notifications"}
                        className="notificaition-common border-0 p-0 list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div className="ms-auto">
                          <div className="h5 mb-0 d-flex align-items-end setting-images">
                            <img
                              src={notifications}
                              alt="notifications"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-12  mt-5">
                      <h5 className="text-black fw-bold mb-4 borderLEFT">
                        Products
                      </h5>
                    </div>


                    <SortingCategory
                      show={show}
                      handleClose={handleClose}
                      selectedFilter={selectedFilter}
                      onDataChange={handleDataChange}
                    />
                    <FilterBy
                      showFilter={showFilter}
                      handleClose={handleClose}
                      selectedCategoryIds={selectedCategoryIds}
                      onCategoryIdPass={onCategoryPropsChangeHandler}
                    />

                    {showLoader && <LoadingAction />}
                  </div>
                </div>
              </div>

              <div className="middle">
                <div className="container-fluid mt-2">
                  <div className="row align-items-center justify-content-center">
                    {/* ============================ */}
                    <div className="col-xl-5 col-lg-4 col-md-12">
                      <div className="border-rounded-12 new">
                        <div className="input-group new">
                          <input
                            type="text"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            className="form-control border-0 bg-none py-2"
                            placeholder="Search..."
                          />
                          <span className="input-group-text bg-none border-0">
                            <img src={searchIcon} alt="search" />
                          </span>
                        </div>
                      </div>


                    </div>

                    <div className="col-xl-3 col-lg-5 col-md-7 col-sm-6 col-xs-12 pe-0">
                      <div className="d-flex align-items-center New-Buttons">
                        <button
                          className=" me-3 btn bg-button sort-by new-sort-by d-flex justify-content-center align-items-center"
                          onClick={sortingHandler}
                        >
                          <i class="fa-solid fa-sort sort me-2"></i>
                          <span className="">Sort By</span>
                        </button>

                        <button
                          className=" me-3 filter-indicator applied btn bg-button sort-by  new-sort-by d-flex justify-content-center align-items-center"
                          onClick={filterHandler}
                        >
                          <i class="fa-solid fa-filter filter me-2"></i>
                          <span className="">Filter</span>
                          {
                            selectedCategoryIds?.length > 0 && <p className="filter-count" >{selectedCategoryIds?.length}</p>
                          }
                          
                        </button>
                        <button
                          className="filter-indicator  btn bg-button sort-by  new-sort-by new-undo d-flex justify-content-center align-items-center bg-white"
                          onClick={filterHandler}
                        >
                          <i class="fa-solid fa-rotate-left sort me-2"></i>
                          <span className="">Undo</span>
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-1 empty-box"></div> */}
                    <div className="col-xl-4 col-lg-3 col-md-5 col-sm-6 col-xs-12">
                      <div className="d-flex justify-content-end align-items-center blk-btn">
                        {/* <button
                          className="btn bg-button sort-by new-sort-by d-flex justify-content-center align-items-center"
                          onClick={sortingHandler}
                        >

                          <i class="fa-solid fa-sort sort me-2"></i>
                          <span className="">Sort By</span>
                        </button>

                        <button
                          className="btn bg-button sort-by  new-sort-by d-flex justify-content-center align-items-center"
                          onClick={filterHandler}
                        >
                          <i class="fa-solid fa-filter filter me-2"></i>
                          <span className="">Filter</span>
                        </button> */}
                        <CSVLink
                          data={staticData}
                          headers={headers}
                          filename="products.csv"
                          className="btn btn-primary bg-button csv"
                          style={{
                            display: "inline-block",
                            textDecoration: "none",
                            padding: "12px 15px",
                            backgroundColor: "#007bff",
                            color: "white",
                            marginRight: "20px",
                          }}
                        >
                          Download CSV
                        </CSVLink>
                        <div className="file-input-container bg-button fw-normal rounded-pill text-white bulk-button">
                          <span className="file-input-label small bulk-lable">
                            <i class="fa-solid fa-plus plus me-2"></i>Bulk
                            Upload Products
                          </span>
                          <input
                            type="file"
                            id="fileInput"
                            name="csv_file"
                            onChange={handleFileChange}
                            accept=".csv"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* ============================ */}
                    <div className="col-md-12 my-4">
                      <p className="fw-semibold">Category</p>
                      <div className="scrollable-cards-container new">
                        {categoryList?.map((category, i) => (
                          <div
                            className={`bg-pink product-category h-100 ${activeCategory == category?._id && "active"
                              }`}
                            key={category?._id}
                            onClick={(e) =>
                              productHandler(
                                e,
                                category?._id,
                                category?.subCategory
                              )
                            }
                          >
                            <div
                              className='bg-pink-2 product-category2 position-relative'

                            >
                              {imageLoader ? (
                                <LoadingImage />
                              ) : (
                                <img
                                  src={GetUrl?.IMAGE_URL + category?.images}
                                  alt="image"
                                  className="img-fluid object-fit-contain"
                                />
                              )}
                            </div>
                            <p className="fw-semibold my-1">
                              {category?.categoryName}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* {activeCategory && subCategoryList.length > 0 && (
                  <div className="col-md-12 mt-4">
                    <p className="fw-semibold">SubCategory</p>
                    <div className="scrollable-cards-container">
                      {subCategoryList?.map((product, index) => (
                        <div className={`bg-pink`} key={index}>
                          <div
                            className={`bg-pink-2 ${
                              activeSubCategory === product?._id && "active"
                            }`}
                            onClick={(e) => subCategoryHandler(e, product?._id)}
                          >
                            <img
                              src={GetUrl?.IMAGE_URL + product?.images}
                              alt="image"
                              className="img-fluid object-fit-contain"
                            />
                          </div>
                          <p className="fw-semibold my-1 sub-category-name">
                            {product?.subCategoryName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )} */}
                    <div className="col-md-6 mt-3">
                      <h5 className="fw-bold design-color mb-0">
                        Total Products : <span className="text-black">{totalProduct}</span>
                      </h5>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="float-md-end mt-3 mt-md-0">
                    <div className="file-input-container bg-button fw-normal py-2 px-4 rounded-pill text-white">
                      <span className="file-input-label small">
                        <img src={plusIcon} alt="plus" width="20" /> Upload CSV
                      </span>
                      <input
                        type="file"
                        id="fileInput"
                        name="csv_file"
                        onChange={handleFileChange}
                        accept=".csv"
                        required
                      />
                    </div>
                  </div> */}
                    </div>
                    <AllProduct
                      productData={productData}
                      onDeleteProductHandler={productDeleteHandler}
                    />
                  </div>
                </div>
              </div>

              <Navbar />
            </div>
          </div>
        </div>
      </div>

      <div className="tooltip-container">
        <button id="floating-button" className="new-float-button  btnn">
          <img
            src={plusIcon}
            alt="plus"
            className="img-fluid"
            onClick={addProductHandler}
          />
        </button>
        <span className="tooltip-text">Add Product</span>
      </div>
    </>
  );
};

export default Products;


