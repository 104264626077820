import React, { useEffect, useState } from 'react'
import Navbar from '../ButtomNav/Navbar'
import PendingDelivery from './pendingDelivery/PendingDelivery'
import notificationIcon from "../../assets/notification-circle.svg"
import ConfirmedDelivery from './confirmedDelivery/ConfirmedDelivery'
import CompleteDelivered from './completeDelivered/CompleteDelivered'
import { useGetOrderStatusQuery } from "../../Services/OrderApi"
import Sidebar from "../../components/Sidebar";
import dlogo from "../../assets/dlogo.png";


const MyMenu = () => {
  const [activeSlider, setActiveSlider] = useState('Confirmed')
  const [orderList, setOrderList] = useState([])
  const { data } = useGetOrderStatusQuery({ status: activeSlider })

  const commonSlider = (data) => {
    console.log('data--->', data);
    setActiveSlider(data)
  }



  useEffect(() => {
    setOrderList(data?.data)
  }, [data, activeSlider])
  console.log('activeSlider-->', activeSlider);

  return (
    <>

      <div className="container-fluid PM_0 p-0">
        <div className="row px-0 pb-md-5 m-0">
          <div className="col-lg-1 col-md-2 new ">
            <Sidebar />
          </div>


          <div className="col-lg-11 col-md-10">

            <div className="section">
              <div className="height-100">
                <div className="top">
                  <div className="container-fluid mt-40 p-0 mx-0">
                    <div className="row align-items-center p-0 m-0 mb-4">
                      <div className="col-3 p-0 m-0">
                        {/* <h5 className="text-black fw-bold mb-0 design-color">Home</h5> */}
                      </div>

                      <div className="col-6 p-0 m-0">
                        <img
                          src={dlogo}
                          alt="logo"
                          className="img-fluid mx-auto d-block"
                          style={{ width: 150 }}
                        />
                      </div>


                      <div className="col-3 p-0 m-0">
                        <a href="/notifications" className="float-end">
                          <img src={notificationIcon} alt="notification" className="img-fluid noti-width" />
                        </a>
                      </div>







                    </div>

                    <div className="row  p-0 mx-0 mt-5">
                      <div className="col-md-12 ">
                        <h5 className="text-black fw-bold mb-4 borderLEFT">My Orders</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="middle">
                  <div className="container-fluid p-0 mx-0">
                    <div className="row p-0 mx-0">


                      <div className="col-md-6 col-sm-6 col-xs-12 mx-0 my-3 tab-list-new">
                        <ul className="nav nav-pills nav-fill" id="menu-tab" role="tablist">
                          {/* <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pending-tab" data-bs-toggle="pill" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">Pending</button>
                          </li> */}

                          <div className="order-container d-flex">
                            <li className="nav-item me-3" role="presentation">
                              <button className="nav-link active confirmed-order-tab" id="confirmed-tab" data-bs-toggle="pill" data-bs-target="#confirmed" type="button" role="tab" aria-controls="confirmed" aria-selected="false" onClick={() => commonSlider('Confirmed')}>Confirmed</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link confirmed-order-tab" id="complete-tab" data-bs-toggle="pill" data-bs-target="#complete" type="button" role="tab" aria-controls="complete" aria-selected="false" onClick={() => commonSlider('Completed')}>Complete</button>
                            </li>
                          </div>

                        </ul>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12 my-3 d-flex justify-content-end align-items-center tab-list-new">
                        <div className="d-flex justify-content-end align-items-center">
                          <button type="button" className="bg-button order-sort me-3" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="fa-solid fa-sort me-2"></i>Sort by</button>
                          <button type="button" className="filter-indicator applied-order bg-button order-filter" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"><i class="fa-solid fa-filter me-2"></i>Filter</button>
                        </div>
                      </div>


                      {/* Order sort modal start */}
                      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className=" border-0 mt-4 pt-0 pb-2 modal-header">
                              <h5 className="d-flex justify-content-center w-100 h5 sort_by_color text-center ms-auto">Sort By</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-md-5">
                              <div class="form-check mb-3">
                                <input className="form-check-input me-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                  New
                                </label>
                              </div>

                              <div class="form-check mb-3">
                                <input className="form-check-input me-3" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                  In-progress
                                </label>
                              </div>

                              <div class="form-check mb-3">
                                <input className="form-check-input me-3" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                <label className="form-check-label" for="flexRadioDefault3">
                                  Completed
                                </label>
                              </div>


                              <div className="row mt-4 mb-3">
                                <div className="col-6">
                                  <button type="button" className="btn bg-button w-100 rounded-pill text-white py-3 fs-16">Apply</button>
                                </div>
                                <div className="col-6">
                                  <button type="button" className="btn bg-button reject w-100 rounded-pill py-3 fs-16 filter-sort-modal" data-bs-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Order sort modal ends */}


                      {/* Filter modal start */}
                      <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className=" border-0 mt-4 pt-0 pb-2 modal-header">
                              <h5 className="d-flex justify-content-center w-100 h5 sort_by_color text-center ms-auto">Filter By</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-md-5">
                              <div class="form-check mb-3">
                                <input className="form-check-input me-3" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                Pending
                                </label>
                              </div>

                              <div class="form-check mb-3">
                                <input className="form-check-input me-3" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                Processing
                                </label>
                              </div>

                              <div class="form-check mb-3">
                                <input className="form-check-input me-3" type="checkbox" name="flexRadioDefault" id="flexRadioDefault3" />
                                <label className="form-check-label" for="flexRadioDefault3">
                                Shipped
                                </label>
                              </div>


                              <div className="row mt-4 mb-3">
                                <div className="col-6">
                                  <button type="button" className="btn bg-button w-100 rounded-pill text-white py-3 fs-16">Apply</button>
                                </div>
                                <div className="col-6">
                                  <button type="button" className="btn bg-button reject w-100 rounded-pill py-3 fs-16 filter-sort-modal" data-bs-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Order sort modal ends */}


                      <div className="col-md-12">
                        <div className="row">
                          <div className="tab-content" id="pills-tabContent">
                            {/* <PendingDelivery /> */}
                            {activeSlider === 'Confirmed' && <ConfirmedDelivery onDataHandlerforConfirm={orderList} activeSliderConfirmed={activeSlider} />}
                            {activeSlider === 'Completed' && <CompleteDelivered onDataCompletedHanlder={orderList} activeSliderCompleted={activeSlider} />}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <Navbar />
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default MyMenu