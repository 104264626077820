import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArrows from "../../assets/arrow-white.png";
import Background from "../../assets/customer-retailer.jpg";
import Customer from "../../assets/customer.png";
import Store from "../../assets/store.png";
import { toast } from "react-toastify";

const ChooseAccount = () => {
    const navigate = useNavigate();

    const [accountType, setAccountType] = useState("Customer");

    const handleAccountChange = (event) => {
        setAccountType(event.target.value);
    };

    const ChooseAccountHandler = () => {
        if (accountType == "Retailer") {
            localStorage.setItem("account_type", accountType);
            navigate("/verification");
        } else {
            toast.success("Please download App from the Play store.");
        }

    };

    return (
        <div className="section bg-white">
            <div className="height-100">
                <div className="middle">
                    <div className="container-fluid p-md-100 h-100 mx-0 px-0">
                        <div className="row h-100 align-items-center mx-0 px-0">
                            <div className="col-lg-6 col-md-12 h-100 hidden-mobile m-0 p-0">
                                <div className="h-100 p-0 m-0">
                                    <img
                                        src={Background}
                                        className="img-fluid h-100 object-fit-cover w-100 rounded-0"
                                        alt="image"
                                    />
                                </div>
                            </div>

                            <div className="col-md-1 hidden"></div>
                            <div className="col-lg-4 col-md-12 choose-account">
                                
                                <Link to={"/register"} className="back-buttons">
                                    <img src={backArrows} alt="arrow" className="img-fluid" />
                                </Link>
                                <div className="choose-account-box">
                                    <h3 className="design-color fw-bolder mt-4 mb-0">
                                        Choose your account type
                                    </h3>
                                    <p className="mb-3 text-muted">
                                        Select the account type that suits your needs to get started.
                                    </p>
                                    <form className="form-login login">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="radio-card-1" className="radio-card mb-4 w-100">
                                                    <input
                                                        type="radio"
                                                        name="radio-card"
                                                        id="radio-card-1"
                                                        value="Customer"
                                                        checked={accountType === "Customer"}
                                                        onChange={handleAccountChange}
                                                    />
                                                    <div className="card-content-wrapper text-end">
                                                        <span className="check-icon"></span>
                                                        <div className="card-content text-start">
                                                            <div className="row align-items-center">
                                                                <div className="col-3 ps-1">
                                                                    <div className="choose-bg">
                                                                        <img
                                                                            src={Customer}
                                                                            className="img-fluid"
                                                                            alt="Customer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-9">
                                                                    <h4>Customer Account</h4>
                                                                    <h5 className="mb-0">
                                                                        Shop for products, track orders, manage
                                                                        preferences, and enjoy exclusive deals.
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="col-md-12">
                                                <label htmlFor="radio-card-2" className="radio-card mb-4 w-100">
                                                    <input
                                                        type="radio"
                                                        name="radio-card"
                                                        id="radio-card-2"
                                                        value="Retailer"
                                                        checked={accountType === "Retailer"}
                                                        onChange={handleAccountChange}
                                                    />
                                                    <div className="card-content-wrapper text-end">
                                                        <span className="check-icon"></span>
                                                        <div className="card-content text-start">
                                                            <div className="row align-items-center">
                                                                <div className="col-3 ps-1">
                                                                    <div className="choose-bg">
                                                                        <img
                                                                            src={Store}
                                                                            className="img-fluid"
                                                                            alt="Retailer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-9">
                                                                    <h4>Retailer Account</h4>
                                                                    <h5 className="mb-0">
                                                                        List products, track sales, grow your business,
                                                                        and increase customer engagement.
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="col-md-10 mx-auto">
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3"
                                                        onClick={ChooseAccountHandler}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseAccount;
