import React from 'react';
import { useNavigate } from 'react-router-dom';
import waitingIcon from "../../assets/waiting.png"
import Modal from 'react-bootstrap/Modal';
import { Image } from 'react-bootstrap';


const PopupHandler = (props) => {
  const navigate = useNavigate();
    const { show, handleClose } = props;

    const submitHandler = (e)=>{
            e.preventDefault();
            navigate('/login')
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton={true} className="border-0 mt-0 pb-2 m-0" style={{margin: "0px"}}>
            </Modal.Header>
            <Modal.Body className='text-center px-md-5 pt-0 pb-4'>
            <div className="modal-body text-center pt-2">
                 <Image src={waitingIcon} alt="image" className="img-fluid waiting-width create profile-image" />
                 <h4 className="design-color fw-bold my-3">Thank you.</h4>
                 <h6 className="text-black fw-bold mb-1">We are evaluating your profile.</h6>
                 <p className="text-muted fw-normal mb-0">The Verification status will update automatically. </p>

              </div>
              <div className="modal-footer justify-content-center border-0">
                <button type="button" className="btn bg-button w-100 rounded-pill text-white py-3 mt-0" onClick={submitHandler}>Go to Home</button>
              </div>
            </Modal.Body>
        </Modal>
    );
}

export default PopupHandler