import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../../../assets/arrow-white.png";
import buttomLine from "../../../../assets/line.svg";
import { useGetFAQQuery } from "../../../../Services/Setting";
import Sidebar from "../../../../components/Sidebar";
import dlogo from "../../../../assets/dlogo.png";
import notifications from "../../../../assets/notification-circle.svg";

const Faq = () => {
  const { data } = useGetFAQQuery();
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    if (data?.data?.length > 0) {
      setFaqList(data?.data);
    }
  }, [data]);
  return (
    <>
      <div className="container-fluid bg-five">
        <div className="row px-0 pb-5 m-0">
          <div className="col-md-2 col-lg-1 new ">
            <Sidebar />
          </div>

          <div className="col-md-10 col-lg-11">
            <div className="section bg-colorss">
              <div className="">
                <div className="top">
                  <div className="container-fluid  mt-40 p-0 mx-0">
                    <div className="row  align-items-center p-0 m-0 mb-5">
                      <div className="col-3 p-0 m-0">
                        <Link className="back-buttons back-button-mobile" to={"/settings"}>
                          <img src={backArrow} alt="arrow" class="img-fluid" />
                        </Link>
                      </div>

                      <div className="col-6 p-0 m-0">
                        <img
                          src={dlogo}
                          alt="notification"
                          className="img-fluid mx-auto d-block"
                          style={{ width: 150 }}
                        />
                      </div>
                      <div className="col-3 p-0 m-0">
                        <Link
                          to={"/notifications"}
                          className="notificaition-common border-0 p-0 list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div className="ms-auto">
                            <div className="h5 mb-0 d-flex align-items-end setting-images">
                              <img
                                src={notifications}
                                alt="notifications"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="middle middle-content">
                  <div className="container-fluid px-0 p-0 mt-2">
                    <div className="row">
                      <div className="col-md-6">
                        {/* <Link className="back-buttons" to={'/settings'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link> */}
                        <h5 className="text-black fw-bold mb-4 borderLEFT">
                          Frequently Asked Questions
                        </h5>
                      </div>

                      <div className="col-md-12">
                        <div
                          className="accordion  new accordion-flush"
                          id="accordionExample"
                        >
                          {faqList?.length > 0 &&
                            faqList?.map((faq, index) => (
                              <div className="accordion-item" key={index}>
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button bg-none shadow-none"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse${index}`}
                                  >
                                    {faq?.question}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${index}`}
                                  className="accordion-collapse collapse show"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body  pt-0 accordation-para">
                                    <p className="mb-2">{faq?.answer}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottom">
                  <div className="container-fluid px-md-100">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center">
                          <img
                            src={buttomLine}
                            alt="line"
                            className="line img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
