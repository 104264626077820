import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../../../assets/arrow-white.png";
import editIcon from "../../../../assets/edit.svg";
import buttomLine from "../../../../assets/line.svg";
import DeleteUser from "./DeleteUser";
import { useRetrieveProfileQuery } from "../../../../Services/UserProfileApi";

const ViewUserProfile = (props) => {
  const { dataPass } = props;
  const { data: userData, refetch } = useRetrieveProfileQuery();
  const [userRetrive, setUserRetrive] = useState("");
  const [show, setShow] = useState(false);
  const deleteAccountHandler = () => {
    setShow(true);
  };
  useEffect(() => {
    setUserRetrive(userData);
  }, [userData]);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (dataPass) {
      refetch();
    }
  }, [dataPass]);
  return (
    <div className="section bg-colorss bg-white mb-3 mb-md-3 order-size-new">
      <div className="">
        <div className="top">
          <div className="container-fluid px-md-5 mt-4">
            <div className="row">
              <div className="col-md-12">
                {/* <Link className="back-buttons" to={"/settings"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link> */}
                <h5 className="design-color fw-bold mt-4 mb-5 text-center">
                  User Profile
                </h5>
              </div>

              <div className="col-md-12 mx-auto">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="text-black mb-0 fw-bolder personal-info">
                          Personal Info
                        </h5>
                      </div>

                      <div className="ms-2">
                        <span>
                          <Link to={"/edit-user-profile"} className=" ">
                            <img
                              src={editIcon}
                              alt="edit"
                              className="img-fluid edit-width me-xl-3"
                            />
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="text-end">
                      <a
                        className="design-color delete-account h6 fw-bold delete_acc"
                        onClick={deleteAccountHandler}
                      >
                        Delete Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="middle update_Profile">
          <div className="container-fluid px-md-5 mt-4">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">Name</p>
                        <h6>{userRetrive?.data?.fullName}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">Mobile Number</p>
                        <h6>{userRetrive?.data?.mobileNumber}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">Email Address</p>
                        <h6>{userRetrive?.data?.email}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">No. of Products</p>
                        <h6>{userRetrive?.data?.numberOfProduct}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">Address</p>
                        <h6>{userRetrive?.data?.address}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">Zip Code</p>
                        <h6>{userRetrive?.data?.zipCode}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card bg-new border-0 mb-4">
                      <div className="card-body px-md-4">
                        <p className="mb-2">
                          Do you have your own delivery staff?{" "}
                        </p>
                        <h6>{userRetrive?.data?.deliveryStaff}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={buttomLine}
                    alt="line"
                    className="line img-fluid mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteUser show={show} handleClose={handleClose} />
    </div>
  );
};

export default ViewUserProfile;
