import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backArrow from "../../assets/logo.png"
import passwordShow from "../../assets/password-show.svg"
import showPassIcon from "../../assets/password-show-icon.svg"
import Background from "../../assets/sbg-image.jpg"
import buttomLine from "../../assets/line.svg";
import { useRegisterPostMutation } from "../../Services/SignUpApi"
import { FormValidation, FormValidationOnChange } from "./FormValidation";
import { toast } from "react-toastify";
import Slider from './Slider'
import LoadingAction from "../loader/LoadingAction"

const Register = () => {
    const [addSignupFormData] = useRegisterPostMutation();
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        role: "retailer"
    });
    const [errField, setErrField] = useState({
        emailErr: "",
        passwordErr: "",
    });

    const submitHandler = (e) => {
        e.preventDefault();

        setShowLoader(true)
        if (FormValidation({ inputField, setErrField })) {
            localStorage.setItem("password", inputField.password);
            setShowLoader(true)
            addSignupFormData(inputField)
                .unwrap()
                .then((res) => {
                    localStorage.setItem("email", res?.data?.email);
                    toast.success(res?.message);
                    setShowLoader(false);
                    if (res?.success) {
                        navigate("/choose-account");
                        setInputField({
                            email: "",
                            password: "",
                            role: ""
                        });
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    toast.error(error?.data?.message);
                });
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
        let inputName = e.target.name
        let inputValue = e?.target?.value
        FormValidationOnChange({ inputValue, setErrField, inputName })
    }



    return (
        <div className="section bg-white">
            <div className="height-100">
                {/* <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row">
                            <div className="col-md-12">
                               
                               
                            </div>
                        </div>
                    </div>
                </div> */}
                {showLoader && <LoadingAction />}
                <div className="middle">
                    <div className="container-fluid h-100 mx-0 px-0">
                        <div className="row h-100 align-items-center justify-content-center mx-0 px-0">

                            <div className="col-xl-5 col-lg-5 col-md-10 register-gap px-lg-4 px-md-0">

                                <div className="top mx-xl-5 mx-lg-4 mx-md-5">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12 mb-4 px-0">
                                                <Link to={'/login'}><img src={backArrow} alt="arrow" className="img-fluid logo-size" /></Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mx-xl-5 mx-lg-4 mx-md-5 register-form">

                                    <h3 className="design-color fw-bolder mt-lg-5 mt-md-4 mb-0">Get Started</h3>
                                    <p className='mb-5 text-muted'>Welcome to Dealshaq Retailer</p>
                                    <form action="" className="form-login">
                                        <div className="mb-4">
                                            <label className='form-label'>Email</label>
                                            <input type="email"
                                                className="form-control py-3"
                                                placeholder="Enter Email Address"
                                                aria-label="email"
                                                aria-describedby="email"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputChangeHandler}
                                            />
                                            {errField?.emailErr?.length > 0 && (
                                                <span className="error">{errField?.emailErr}</span>
                                            )}
                                        </div>

                                        <div className="position-relative">
                                            <label className='form-label'>Password</label>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                className="form-control py-3 position-relative"
                                                placeholder="Enter Password" aria-label="password"
                                                aria-describedby="password"
                                                name="password"
                                                value={inputField.password}
                                                onChange={inputChangeHandler}
                                            />
                                            <img src={showPassword ? showPassIcon : passwordShow} alt="password-show" className="img-fluid position-absolute password-show" onClick={togglePasswordVisibility} />
                                        </div>
                                        {errField?.passwordErr?.length > 0 && (
                                            <span className="error">{errField?.passwordErr}</span>
                                        )}

                                        <div className="col-md-10 mx-auto">
                                            <div className='text-center'>
                                                <button type="submit"
                                                    className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-5 mt-xl-5 mt-5"
                                                    onClick={submitHandler}
                                                >Continue</button>
                                            </div>
                                        </div>

                                        <h6 className="text-center mt-4">
                                            <span className="text-black me-2">Already have an Account?</span>
                                            <Link to={'/login'} className="design-color fw-bold text-decoration-none">Log in</Link>
                                        </h6>

                                    </form>
                                </div>
                            </div>



                            <div className="col-lg-7 col-md-12 h-100 hidden-mobile p-0">
                                <div className='h-100 rounded-0'>


                                    <Slider />



                                    {/* <img src={Background}  className="img-fluid h-100 object-fit-cover w-100" alt="image" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">
                               
                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Register