import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import backArrow from "../../assets/arrow-white.png"
import buttomLine from "../../assets/line.svg"
import { FormValidation, FormValidationOnChange } from "./CreateProfileValidation"
import PopupHandler from './PopupHandler'
import { useCreateUserProfilePostMutation } from "../../Services/CreateProfileApi"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LoadingAction from '../loader/LoadingAction'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const CreateProfile = () => {
    const dispatch = useDispatch();
    const [addUseProfile] = useCreateUserProfilePostMutation();
    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        numberOfProduct: '',
        address: '',
        zipCode: '',
        deliveryStaff: ''
    });

    const [errField, setErrField] = useState({
        fullNameErr: '',
        mobileNumberErr: '',
        numberOfProductsErr: '',
        addressErr: '',
        zipCodeErr: '',
    });

    const handleClose = () => {
        setShow(false);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        let inputValue = value;
        if (name === 'zipCode') {
            inputValue = value.replace(/\D/g, '');
            if (inputValue.length > 5) {
                inputValue = inputValue.slice(0, 5);
            }
        } else if (name === 'mobileNumber') {
            inputValue = value.replace(/\D/g, ''); 
            if (inputValue.length > 13) {
                inputValue = inputValue.slice(0, 13); 
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: inputValue
        }));
        let inputName = name;
        FormValidationOnChange({ inputValue, setErrField, inputName, dispatch });
    };
    const handlePhoneNumberChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            mobileNumber: value
        }));
        const inputName = 'mobileNumber';
        FormValidationOnChange({ inputValue: value, setErrField, inputName, dispatch });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (FormValidation({ formData, setErrField })) {
            let user = localStorage.getItem('email')
            formData['email'] = user
            setShowLoader(true)
            addUseProfile(formData)
                .unwrap()
                .then((res) => {
                    if (res?.success) {
                        setShowLoader(false)
                        setShow(true);
                        setFormData({
                            fullName: '',
                            mobileNumber: '',
                            numberOfProducts: '',
                            address: '',
                            zipCode: '',
                            deliveryStaff: ''
                        })

                    }
                })
                .catch((err) => {
                    console.log();
                    setShowLoader(false);
                    toast.error(err?.data?.message);
                });
        }

    };
   
    return (
        <>
            <div className="section bg-colorss">
                <div className="height-100">
                    <div className="top">
                        <div className="container-fluid px-md-100 mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link className="back-buttons" to={'/verification'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {showLoader && <LoadingAction />}
                    <div className="middle my-4 px-3">
                        <div className="ontainer-fluid px-md-100 h-100">
                            <div className="row justify-content-center">
                                <div className="col-xl-8 col-lg-10 col-md-12 bg-white p-3">
                                <h4 className="design-color fw-bold  mt-4 mb-5 text-center">Complete your Profile</h4>
                                    <form action="" className="form-login">



                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="mb-4">
                                        <label className='form-label'>Full Name</label>
                                            <input type="text"
                                                name="fullName"
                                                value={formData.fullName}
                                                onChange={handleChange}
                                                className="form-control py-3"
                                                placeholder="Enter Full Name" aria-label="fullname"
                                                aria-describedby="fullname" />
                                            {errField?.fullNameErr?.length > 0 && (
                                                <span className="error">{errField?.fullNameErr}</span>
                                            )}
                                        </div>

                                            </div>
                                            <div className="col-md-6">
                                            <div className="mb-4 us-number">
                                        <label className='form-label'>Phone Number</label>
                                        <PhoneInput
                                                country={'us'} // Default country
                                                name="mobileNumber"
                                                value={formData.mobileNumber}
                                                onChange={handlePhoneNumberChange}
                                                onlyCountries={['us', 'ca', 'au']} // Limit to US, Canada, and Australia
                                                disableDropdown={false}
                                                placeholder="Enter phone number"
                                                inputStyle={{ width: '300px' }}
                                            />
                                            {errField?.mobileNumberErr?.length > 0 && (
                                                <span className="error">{errField?.mobileNumberErr}</span>
                                            )}
                                        </div>

                                            </div>


                                            
                                    
                                      

                                        {/* <div className="mb-4">
                                            <input type="number"
                                                name="mobileNumber"
                                                value={formData.mobileNumber}
                                                onChange={handleChange}
                                                className="form-control py-3"
                                                placeholder="Mobile Number"
                                                aria-label="mobilenumber"
                                                aria-describedby="mobilenumber" />
                                            {errField?.mobileNumberErr?.length > 0 && (
                                                <span className="error">{errField?.mobileNumberErr}</span>
                                            )}
                                        </div> */}



                                        <div className="col-md-6">

                                              
                                        <div className="mb-4">
                                        <label className='form-label'>Number of Products</label>
                                            <input type="number"
                                                name="numberOfProduct"
                                                value={formData.numberOfProduct}
                                                onChange={handleChange}
                                                className="form-control py-3"
                                                placeholder="Enter Number of Products"
                                                aria-label="productnumber"
                                                aria-describedby="productnumber" />
                                            {errField?.numberOfProductsErr?.length > 0 && (
                                                <span className="error">{errField?.numberOfProductsErr}</span>
                                            )}
                                        </div>

                                        


                                        </div>
                                        <div className="col-md-6">
                                        <div className="mb-4">
                                        <label className='form-label'>Zip Code</label>
                                            <input type="number"
                                                name="zipCode"
                                                value={formData.zipCode}
                                                onChange={handleChange}
                                                className="form-control py-3"
                                                placeholder="Enter Zip Code"
                                                aria-label="zipcode"
                                                aria-describedby="zipcode" />
                                            {errField?.zipCodeErr?.length > 0 && (
                                                <span className="error">{errField?.zipCodeErr}</span>
                                            )}
                                        </div>

                                        </div>
                                        </div>

                                    
                                        <div className="mb-4">
                                        <label className='form-label'>Address</label>
                                            <textarea
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                                cols="30"
                                                rows="4" className="form-control"
                                                placeholder="Enter Address"></textarea>
                                            {errField?.addressErr?.length > 0 && (
                                                <span className="error">{errField?.addressErr}</span>
                                            )}
                                        </div>

                                       
                                        <div className="">
                                            <label htmlFor="radio" className="design-color form-label  fw-bolder text-muted h6">
                                                Do you have your own delivery staff?
                                            </label>

                                            <div className="mt-2 deliverystaff">
                                                <div className="form-check form-check-inline me-5">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="deliveryStaff"
                                                        id="yes"
                                                        value="Yes"
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="yes">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="deliveryStaff"
                                                        id="inlineRadio2"
                                                        value="No"
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                                </div>
                                            </div>


                                        </div>


<div className="col-md-8 mx-auto">

<button type="button"
                                            onClick={(e) => submitHandler(e)}
                                            // data-bs-toggle={`${show && 'modal'}`}
                                            data-bs-target="#profile-verification"
                                            className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mb-4 mt-3">Submit</button>
</div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="bottom">
                        <div className="container-fluid px-md-100">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="text-center">
                                        <img src={buttomLine} alt="line" className="line img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
            <PopupHandler show={show} handleClose={handleClose} />
            {/* <div className="modal fade" id="profile-verification" tabIndex="-1" aria-labelledby="profileVerification" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content pb-4">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <img src={waitingIcon} alt="image" className="img-fluid waiting-width" />
                            <h3 className="design-color fw-bold my-3">Thank you.</h3>
                            <h6 className="text-black fw-bold mb-2">We are evaluating your profile.</h6>
                            <p className="text-black fw-normal">The Verification status will update automatically. </p>

                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button type="button" className="btn bg-button w-100 rounded-pill text-white py-2">Go to Home</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default CreateProfile